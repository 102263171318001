import { FC } from 'react'
import { FooterSocialIconLinks } from '@components-v2/molecules/FooterSocialIconLinks'
import { Logo } from '@components-v2/atoms/Logo'
import { useTranslation } from 'next-i18next'
import { FooterLinkColumns } from '@components-v2/molecules/FooterLinkColumns'
import styles from './index.module.scss'

type MenuItem = {
  text: string
  href: string
  isExternal?: boolean
}

type MenuColumn = {
  heading: string
  items: MenuItem[]
}

// TODO 言語設定のリンクを追加する
export const GlobalFooter: FC = () => {
  const { t } = useTranslation(['global'])

  const menuColumn1: MenuColumn = {
    heading: 'Product', // TODO 翻訳
    items: [
      {
        text: t('記事'),
        href: '/articles',
      },
      {
        text: t('求人'),
        href: '/companies-with-jobs',
      },
      {
        text: t('エンジェル投資家を探す'),
        href: '/angels',
      },
      {
        text: t('ファンドを探す'),
        href: '/vcs',
      },
      {
        text: t('スタートアップを探す'),
        href: '/startups',
      },
      {
        text: t('投資管理クラウド'),
        href: 'https://prosupporters.protocol.ooo/investorcloud',
        isExternal: true,
      },
    ],
  }

  const menuColumn2: MenuColumn = {
    heading: 'Other Services', // TODO 翻訳
    items: [
      {
        text: t('求人サービス'),
        href: 'https://lp.recruiting.protocol.ooo/',
        isExternal: true,
      },
      {
        text: t('会員限定特典'),
        href: 'https://prosupporters.protocol.ooo/',
        isExternal: true,
      },
      {
        text: t('PROTOCOL ピッチイベント'),
        href: 'https://propitch.protocol.ooo/',
        isExternal: true,
      },
      {
        text: t('共同ピッチイベント'),
        href: 'https://pivot.protocol.ooo/',
        isExternal: true,
      },
    ],
  }

  const menuColumn3: MenuColumn = {
    heading: 'Company', // TODO 翻訳
    items: [
      {
        text: t('運営会社'),
        href: 'https://corp.protocol.ooo',
        isExternal: true,
      },
      {
        text: t('お問い合わせ'),
        href: process.env.NEXT_PUBLIC_SUPPORT_FORM_URL,
        isExternal: true,
      },
      {
        text: t('広告掲載のお問い合わせ'),
        href: process.env.NEXT_PUBLIC_CONTACT_ABOUT_AD,
        isExternal: true,
      },
    ],
  }

  const menuColumn4: MenuColumn = {
    heading: 'Legal', // TODO 翻訳
    items: [
      {
        text: t('個人情報保護方針'),
        href: '/privacypolicy',
      },
      {
        text: t('利用規約'),
        href: '/terms',
      },
    ],
  }

  const columns: MenuColumn[] = [menuColumn1, menuColumn2, menuColumn3, menuColumn4]

  return (
    <footer className={styles.footer}>
      <div className='container'>
        <div className={styles.upper}>
          <div className={styles.upper__logo}>
            <Logo />
          </div>
          <div className={styles.upper__menus}>
            <FooterLinkColumns columns={columns} />
          </div>
        </div>
      </div>
      <div className='container'>
        <div className={styles.bottom}>
          <div className={styles.bottom__copyright}>
            <p>&copy; {new Date().getFullYear()} PROTOCOL, Inc. All rights Reserved.</p>
          </div>
          <div className={styles.bottom__snsLink}>
            <FooterSocialIconLinks />
          </div>
        </div>
      </div>
    </footer>
  )
}
