import { FC } from 'react'
import Link from 'next/link'
import { getArticleThumbnail, getDateTimeFromUtc } from '@/utils'
import { IArticleBase } from '@/types'
import styles from './index.module.scss'

type Props = {
  article: IArticleBase
}

export const TopPageArticleCard: FC<Props> = ({ article }) => {
  return (
    <Link href={`/articles/${article?.slug}`}>
      <a className={styles.container}>
        <div className={styles.thumbnail}>
          <img src={getArticleThumbnail(article)} alt={article?.title} />
        </div>
        <div className={styles.content}>
          <p className={styles.meta}>{getDateTimeFromUtc(article.createdAt, 'YYYY.MM.DD')}</p>
          <p className={styles.title}>{article?.title}</p>
        </div>
      </a>
    </Link>
  )
}
