import { FC } from 'react'
import { FooterLinkColumnHeading } from '@components-v2/atoms/FooterLinkColumnHeading'
import { FooterLinks } from '@components-v2/molecules/FooterLinks'
import styles from './index.module.scss'

type Props = {
  heading: string
  items: {
    href: string
    text: string
  }[]
}

export const FooterLinkColumn: FC<Props> = ({ heading, items }) => {
  return (
    <div className={styles.container}>
      <div className={styles.heading}>
        <FooterLinkColumnHeading>{heading}</FooterLinkColumnHeading>
      </div>
      <div className={styles.items}>
        <FooterLinks items={items} />
      </div>
    </div>
  )
}
