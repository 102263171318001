import { FC, PropsWithChildren } from 'react'
import Link from 'next/link'
import styles from './index.module.scss'

type Props = {
  href: string
  isExternal?: boolean
}

export const FooterLink: FC<PropsWithChildren<Props>> = ({ href, children, isExternal = false }) => {
  if (isExternal) {
    return (
      <Link href={href}>
        <a className={styles.container} target='_blank'>
          {children}
        </a>
      </Link>
    )
  }

  return (
    <Link href={href}>
      <a className={styles.container}>{children}</a>
    </Link>
  )
}
