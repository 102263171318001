import { FC } from 'react'
import { FooterLink } from '@components-v2/atoms/FooterLink'
import styles from './index.module.scss'

type Props = {
  items: {
    href: string
    text: string
    isExternal?: boolean
  }[]
}

export const FooterLinks: FC<Props> = ({ items }) => {
  return (
    <div className={styles.container}>
      {items.map((item) => (
        <FooterLink href={item.href} key={item.href} isExternal={item?.isExternal}>
          {item.text}
        </FooterLink>
      ))}
    </div>
  )
}
