import { FC } from 'react'
import { TopPageArticleCard } from '@components-v2/molecules/TopPageArticleCard'
import { IArticle } from '@/types'
import { GridCardList } from '@/componentsV2/molecules/GridCardList'

type Props = {
  items: IArticle[]
}

export const TopPageArticleCardList: FC<Props> = ({ items = [] }) => {
  return (
    <GridCardList>
      {items.map((item) => (
        <TopPageArticleCard key={item.slug} article={item} />
      ))}
    </GridCardList>
  )
}
