import { FC } from 'react'
import { FooterLinkColumn } from '@components-v2/molecules/FooterLinkColumn'
import styles from './index.module.scss'

type ColumnItem = {
  heading: string
  items: {
    href: string
    text: string
  }[]
}

type Props = {
  columns: ColumnItem[]
}

export const FooterLinkColumns: FC<Props> = ({ columns }) => {
  return (
    <div className={styles.container}>
      {columns.map((column) => (
        <FooterLinkColumn heading={column.heading} items={column.items} key={column.heading} />
      ))}
    </div>
  )
}
