import React, { FC } from 'react'
import { GeneralSectionTitle } from '@components-v2/atoms/GeneralSectionTitle'
import styles from './index.module.scss'

type Props = {
  heading?: string
  withTopDivider?: boolean
  withDivider?: boolean
  footer?: React.ReactNode
}

export const GeneralSection: FC<Props> = ({
  children,
  heading = '',
  footer = null,
  withTopDivider = false,
  withDivider = true,
}) => {
  return (
    <div className='container'>
      {withTopDivider && <div className={styles.divider} />}
      <div className={styles.inner}>
        {heading && <GeneralSectionTitle>{heading}</GeneralSectionTitle>}
        <div className={styles.content}>{children}</div>
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
      {withDivider && <div className={styles.divider} />}
    </div>
  )
}
