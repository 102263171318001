import { FC, ReactNode } from 'react'
import { sanitizeUrl } from '@/utils'
import styles from './index.module.scss'

type Props = {
  iconNode: ReactNode
  href: string
  isSmall?: boolean
}

export const SocialIconLink: FC<Props> = ({ iconNode, href, isSmall = false }) => {
  return (
    <a className={styles.container} href={sanitizeUrl(href)} target='_blank' rel='nofollow noopener noreferrer'>
      <div className={isSmall ? styles.smallIcon : styles.icon}>{iconNode}</div>
    </a>
  )
}
