import { FC, ReactNode } from 'react'
import { sanitizeUrl } from '@/utils'
import TwitterSvg from '@/public/images/social_icons/twitter-gray_v2.svg'
import FacebookSvg from '@/public/images/social_icons/facebook-gray_v2.svg'
import { SocialIconLink } from '@components-v2/atoms/SocialIconLink'
import styles from './index.module.scss'

type LinkItem = {
  href: string
  iconNode: ReactNode
}

const linkItems: LinkItem[] = [
  {
    href: 'https://facebook.com/protocolooo',
    iconNode: <FacebookSvg viewBox='0 0 24 24' />,
  },
  {
    href: 'https://twitter.com/protocolooo',
    iconNode: <TwitterSvg viewBox='0 0 24 24' />,
  },
  // TODO LINE
  // {
  //   href:
  //     'https://liff.line.me/1645278921-kWRPP32q?accountId=635ltzlf&openerPlatform=webview&openerKey=webview%3AunifiedSearch#mst_challenge=kNs9HyjjXjpwrWXxBBgae3ka2jSYA-mR7zoWto1Q6XE',
  //   iconNode: '/images/common/line_footer.svg',
  // },
]

export const FooterSocialIconLinks: FC = () => {
  return (
    <div className={styles.container}>
      {linkItems.map((item) => (
        <SocialIconLink key={item.href} href={sanitizeUrl(item.href)} iconNode={item.iconNode} />
      ))}
    </div>
  )
}
