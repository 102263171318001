import { FC } from 'react'
import { observer } from 'mobx-react'
import ArrowSvg from '@/public/images/common/arrow-narrow-right_v2.svg'
import { IArticle } from '@/types'
import { TopPageArticleCardList } from '@components-v2/molecules/TopPageArticleCardList'
import { useTranslation } from 'next-i18next'
import { NoContent } from '@components-v2/atoms/NoContent'
import { GeneralSection } from '@/componentsV2/organisms/GeneralSection'
import { Button2 } from '@components-v2/atoms/Button2'
import styles from './index.module.scss'

type Props = {
  items: IArticle[]
  withDivider?: boolean
}

export const TopPageStoriesSection: FC<Props> = observer(({ items, withDivider = true }) => {
  const { t } = useTranslation(['index'])
  const Footer = () => (
    <Button2
      href='/articles/categories/stories'
      componentType='a'
      size='xl'
      hierarchy='secondary-color'
      rightIcon={<ArrowSvg viewBox='0 0 24 24' />}
    >
      {t('button.viewAllStories')}
    </Button2>
  )

  return (
    <GeneralSection heading={t('title.stories')} withDivider={withDivider} footer={<Footer />}>
      {items?.length === 0 ? (
        <div className={styles.noContent}>
          <NoContent>{t('ストーリーはまだ掲載されていません。')}</NoContent>
        </div>
      ) : (
        <div className={styles.itemList}>
          <TopPageArticleCardList items={items} />
        </div>
      )}
    </GeneralSection>
  )
})
